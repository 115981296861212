import React from 'react';
import LocalParticipant from './LocalParticipant';
import RemoteParticipant from './RemoteParticipant';
import {useMeeting} from '../hooks';
import {Typography} from '@material-ui/core';


export default function Meeting() {
    const {room, meetingCode, remoteParticipants} = useMeeting();
    
    return (
        <>
            <Typography>{meetingCode}</Typography>
            <Typography>{room?.name} </Typography>
            <LocalParticipant /> 
            {remoteParticipants && remoteParticipants.map(participant => {
                return (<RemoteParticipant key={participant.sid} participant={participant} />)
            })}
        </>
        );
}