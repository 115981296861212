import { createMuiTheme } from '@material-ui/core';

// declare module '@material-ui/core/styles/createMuiTheme' {
//   interface Theme {
//     sidebarWidth: number;
//     sidebarMobileHeight: number;
//     brand: string;
//     footerHeight: number;
//     mobileTopBarHeight: number;
//     mobileFooterHeight: number;
//     sidebarMobilePadding: number;
//     participantBorderWidth: number;
//   }

//   // allow configuration using `createMuiTheme`
//   interface ThemeOptions {
//     sidebarWidth?: number;
//     sidebarMobileHeight?: number;
//     brand: string;
//     footerHeight: number;
//     mobileTopBarHeight: number;
//     mobileFooterHeight: number;
//     sidebarMobilePadding: number;
//     participantBorderWidth: number;
//   }
// }

//const defaultTheme = createMuiTheme();

export default createMuiTheme({
      // palette: {
      //   primary: {
      //     main: "#fff",
      //   },
      //   secondary: {
      //     main: "#F9B84A",
      //   },
      //   background: {
      //     default: "#000",
      //   },
      //   text: {
      //     primary: "#fff",
      //     secondary: "#F9B84A",
      //   },
      // },
      typography: {
        h3: {
          //fontSize: '1.5rem',
          fontWeight: "bold",
          "@media (min-width:850px)": {
            //fontSize: '1.8rem',
          },
        },
        h4: {
          //fontSize: '1.1rem',
          "@media (min-width:850px)": {
            //fontSize: '1.5rem',
          },
        },
      },
});

