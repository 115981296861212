import React, {useEffect, useState} from 'react';
import {Button, IconButton} from '@material-ui/core';
import {LocalAudioTrack as ILocalAudioTrack, LocalVideoTrack as ILocalVideoTrack} from 'twilio-video';

import Icon from './Icons';
import useMeeting from '../hooks/useMeeting';

interface MeetingControlsProps {
    localAudioTrack:ILocalAudioTrack | undefined,
    localVideoTrack:ILocalVideoTrack | undefined,
}

export default function MeetingControls({localAudioTrack, localVideoTrack}:MeetingControlsProps) {
    const {room} = useMeeting();
    const [isAudioMuted, setIsAudioMuted] = useState(true);
    const [isVideoMuted, setIsVideoMuted] = useState(false);

    useEffect(() => {
        if (isAudioMuted) {
            localAudioTrack?.disable();
        } 
        else {
            localAudioTrack?.enable();
        }
    }, [localAudioTrack, isAudioMuted])

    useEffect(() => {
        if (isVideoMuted) {
            localVideoTrack?.disable();
        } 
        else {
            localVideoTrack?.enable();
        }
    }, [localVideoTrack, isVideoMuted])

    function leaveHandler() {
        room?.disconnect();
    }

    function shareScreenHandler() {

    }

    function muteAudioHandler() {
        setIsAudioMuted(prevState => !prevState);
    }

    function muteVideoHandler() {
        setIsVideoMuted(prevState => !prevState);
    }

    return (
        <>
            <Button onClick={muteAudioHandler}>{isAudioMuted ? 'Unmute Audio': 'Mute Audio'}</Button>
            <Button onClick={muteVideoHandler}>{isVideoMuted ? 'Unmute Video': 'Mute Video'}</Button>
            <Button onClick={shareScreenHandler}>Share Screen</Button>
            <IconButton onClick={leaveHandler} >
                <Icon type='callend'/> 
            </IconButton>
        </>
    );
}