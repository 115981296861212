import { Typography } from '@material-ui/core';
import React, { useDebugValue } from 'react';
// import {Typography} from '@material-ui/core';
import { Participant as IParticipant } from 'twilio-video';
import ParticipantTracks from './ParticipantTracks';


interface RemoteParticipantProps {
    participant:IParticipant
}

export default function RemoteParticipant({participant}:RemoteParticipantProps) {
    useDebugValue(participant.sid);

    return (
        <>
            <Typography>Participant SID: {participant.sid}</Typography>
            <Typography>Name: {participant.identity}</Typography>
            <ParticipantTracks participant={participant} videoOnly={false} isLocalParticipant={false} />
        </>
    );

}