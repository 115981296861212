import React from 'react';
// import { styled } from '@material-ui/core/styles';
import useMeeting from './hooks/useMeeting';
import {Container, CircularProgress} from '@material-ui/core';
import JoinMeeting from './components/JoinMeeting';
import Meeting from './components/Meeting';
import {version} from '../package.json';


export default function App() {
  const {connectionStatus} = useMeeting();

  let element:JSX.Element = <></>;

  switch (connectionStatus) {
    case 'disconnected':
      element = <JoinMeeting />;
      break;
    case 'connecting':
    case 'reconnecting':
      element  = <CircularProgress /> 
      break;
    case 'connected': 
      element = <Meeting />;
      break;
  }

  return (
      <Container maxWidth='md'>
        {element}
        <p>Version {version}</p>
      </Container>
      
  );
}
