import React, {SyntheticEvent, useState} from 'react';
import {Button, TextField, Box} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {useMeeting} from '../hooks';

const useStyles = makeStyles((theme) => ({
    root: {
      '& > *': {
        margin: theme.spacing(2),
      },
      width: '50%',
      margin:'auto',
    },
  }));

export default function JoinMeeting() {
    const {joinMeeting, createMeeting}  = useMeeting();
    const [formCode, setFormCode] = useState<string>('');
    // const [formName, setFormName] = useState<string>('');

    const styles = useStyles();

    function handleJoin(event:SyntheticEvent) {
        joinMeeting(formCode);
    }

    function handleCreate(event:SyntheticEvent) {
      createMeeting();
  }

    const disbleJoin = formCode.length !== 6;
    // const disbleCreate = formName.length === 0;


    return(
      <Box className={styles.root}>
        {/* <TextField id='name' label='Your Name' required value={formName} onChange={(event) => setFormName(event?.target.value)} />  */}
        <TextField id='code' label='Meeting Code' required value={formCode} onChange={(event) => setFormCode(event?.target.value)} /> 
        <Button disabled={disbleJoin} onClick={handleJoin}>Join</Button>
        <Button onClick={handleCreate}>Create</Button>
      </Box>
    );
}
