import { useContext } from 'react';
import  {MeetingContext}  from '../components/MeetingProvider';

export default function useMeeting() {
  const context = useContext(MeetingContext);
  if (!context) {
    throw new Error('Could not find MeetingProvider up the component tree');
  }
  return context;
}
