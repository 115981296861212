import React,  {useEffect, useState, useRef} from 'react';
import {useMeeting} from '../hooks';
import {createLocalTracks, LocalAudioTrack, LocalVideoTrack} from 'twilio-video';
import MeetingControls from './MeetingControls';

export default function LocalParticipant() {
    const videoElement = useRef<HTMLVideoElement>(null!);
    const [audioTrack, setAudioTrack] = useState<LocalAudioTrack | undefined>(undefined);
    const [videoTrack, setVideoTrack] = useState<LocalVideoTrack | undefined>(undefined);
    const {room} = useMeeting();

    //publish tracks 
    useEffect(() => {
        if (!room) return; //nothing to publish if we arent in a room. 
       
        let tracks: (LocalAudioTrack | LocalVideoTrack)[] = [];

        if (audioTrack && !room.localParticipant.audioTracks.has(audioTrack.id)){
            tracks.push(audioTrack);
        }

        if (videoTrack && !room.localParticipant.videoTracks.has(videoTrack.id)){
            tracks.push(videoTrack);
        }

        room.localParticipant.publishTracks(tracks);

        return () => {
            room.localParticipant.unpublishTracks(tracks);
        }
    }, [room, audioTrack, videoTrack])
    
    //video setup
    useEffect(() => {

        const vel = videoElement?.current;
        let videoStream: MediaStream;

        (async () => {
            if (!vel) return;
    
            if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
              throw new Error(
                  'Browser API navigator.mediaDevices.getUserMedia not available');
            }
          
            videoStream = await navigator.mediaDevices.getUserMedia(
                {'audio': false, 'video': true});

            vel.srcObject = videoStream;

            const localVideo = new LocalVideoTrack(videoStream.getTracks()[0]);
            setVideoTrack(localVideo);
        
        })();
       
        return () => {
            vel.srcObject = null;
            videoStream.getTracks().forEach((track) => track.stop());
        };
    }, []);

    //audio setup
    useEffect(() => {

        (async () => {          
            const tracks = await createLocalTracks({audio: true});
            if (tracks && tracks.length > 0) {
                setAudioTrack(tracks[0] as LocalAudioTrack);
            }

        })();
        return () => {
            // tracks.forEach((track) => track.)
        }
    }, []);

    return (
        <>
            <video ref={videoElement} style={{display: 'none'}}/>
            {/* <Button onClick={() => {
                setBlurBackground(!blurBackground);
            }}>Switch blur {blurBackground ? 'off' : 'on' }</Button>         */}
            <MeetingControls localAudioTrack={audioTrack} localVideoTrack={videoTrack}/> 
        </>
    );
}