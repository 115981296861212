import React from "react";
// import { SvgIcon } from "@material-ui/core";
import {
	CallEnd,
} from "@material-ui/icons";

function Icon(props: any) {
	if (!props.type) {
		return <> </>;
	}

	switch (props.type) {
		case "callend":
			return <CallEnd {...props} />;

		default:
			return <> </>;
	}
}

export default Icon;
