import React,  {createContext, ReactNode, useEffect, useState, useCallback} from 'react';
import {Room, RemoteParticipant, connect} from 'twilio-video';
import Axios from 'axios';

export type ConnectionStatusType = 'disconnected' | 'connected' | 'reconnecting' | 'connecting';

export interface IMeetingContext {
    room?: Room,
    connectionStatus: ConnectionStatusType,
    joinMeeting:(code: string) => void,
    createMeeting:() => void,
    meetingCode:string,
    remoteParticipants: RemoteParticipant[],
}

export interface IMeetingProviderProps {
    children: ReactNode
}

export const MeetingContext = createContext<IMeetingContext>(null!);


export default function MeetingProvider(props:IMeetingProviderProps) {

    const [room, setRoom] = useState<Room | undefined>();
    const [connectionStatus, setConnectionStatus] = useState<ConnectionStatusType>('disconnected');
    const [meetingCode, setMeetingCode] = useState('');
    const [remoteParticipants, setRemoteParticipants] = useState<RemoteParticipant[]>([]);

    const createMeeting = useCallback(() => {
        (async () => {
            try {
                setConnectionStatus('connecting');
                const response = await Axios.post('https://api.meet.jimkadunc.com/meetings');
    
                const currentRoom = await connect(response.data.token, {
                    audio:false,
                    video:false,
                    logLevel:'info',
                });
    
                
                setMeetingCode(response.data.meetingCode);
                setRoom(currentRoom);
                setConnectionStatus('connected'); 
            } catch (error) {
                setConnectionStatus('disconnected');
            }
        })();

    }, []);

    const joinMeeting = useCallback((code:string) => {
        if (!code) return;

        (async () => {
            try {
                setConnectionStatus('connecting');
                const response = await Axios.get('https://api.meet.jimkadunc.com/meetings', {
                    params: {
                        code:code,
                    }
                });
    
                switch (response.data.status)
                {
                    case 'token_generated':
                        if (response.data.token){
                            const currentRoom = await connect(response.data.token, {
                                audio:false,
                                video:false,
                                logLevel:'info',
                            });
                            setRoom(currentRoom);
                            setMeetingCode(code);
                            setConnectionStatus('connected');
                        }
                        else {
                            console.error(response);
                        }
                        break;
                    case 'invalid_meeting_code':
                        break;
                    default:
                        console.error('didnt see that coming');
                }
            } catch (error) {
                setConnectionStatus('disconnected');
            }

        })();
    }, []);


    // when we connect, set the remote participants
    useEffect(() => {
        if (room && connectionStatus === 'connected'){
            setRemoteParticipants(Array.from(room.participants.values()));
        }
        else {
            setRemoteParticipants([]); 
        }
    }, [connectionStatus, room]);

    // set up event handlers
    useEffect(() => {
        
        function disconnect() { room?.disconnect();}

        window.addEventListener('beforeunload', disconnect);

        function onParticipantConnected(participant:RemoteParticipant) {
            setRemoteParticipants(prevState => [...prevState, participant]);
        }
        function onParticipantDisconnected(participant:RemoteParticipant) {
            setRemoteParticipants(prevState => prevState.filter(p => p !== participant));
        }
        function onReconnecting() {
            setConnectionStatus('reconnecting');
        }
        function onReconnected() {
            setConnectionStatus('connected');
        }
        function onDisconnected() {
            setConnectionStatus('disconnected');
            window.removeEventListener('beforeunload', disconnect)
        }
        room?.on('reconnected', onReconnected);
        room?.on('participantConnected', onParticipantConnected);
        room?.on('participantDisconnected', onParticipantDisconnected);
        room?.on('disconnected', onDisconnected);
        room?.on('reconnecting', onReconnecting);

        return () => {
            room?.off('reconnected', onReconnected);
            room?.off('particpantConnected', onParticipantConnected);
            room?.off('particpantDisconnected', onParticipantConnected);
            room?.off('disconnected', onDisconnected);
            room?.off('reconnecting', onReconnecting);
            window.removeEventListener('beforeunload', disconnect)

        }
    }, [room]);

    const context:IMeetingContext = {
        room:room,
        connectionStatus:connectionStatus,
        joinMeeting:joinMeeting,
        createMeeting:createMeeting,
        meetingCode:meetingCode,
        remoteParticipants:remoteParticipants,
    }
    
    return (
        <MeetingContext.Provider value={context}>
            {props.children}
        </MeetingContext.Provider>
    );
}