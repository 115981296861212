import React, { useDebugValue } from 'react';
import { Participant, Track, LocalTrackPublication, RemoteTrackPublication, LocalVideoTrack, RemoteVideoTrack, AudioTrack as IAudioTrack } from 'twilio-video';
import AudioTrack from './AudioTrack';
import VideoTrack from './VideoTrack';
import {usePublications, useTrack} from '../hooks';

interface ParticipantTracksProps {
  participant: Participant;
  videoOnly?: boolean;
  enableScreenShare?: boolean;
  videoPriority?: Track.Priority | null;
  isLocalParticipant?: boolean;
}

interface PublicationProps {
  publication: LocalTrackPublication | RemoteTrackPublication;
  participant: Participant;
  isLocalParticipant?: boolean;
  videoOnly?: boolean;
  videoPriority?: Track.Priority | null;
}

type IVideoTrack = LocalVideoTrack | RemoteVideoTrack;


export default function ParticipantTracks({
  participant,
  videoOnly,
  enableScreenShare,
  videoPriority,
  isLocalParticipant,
}: ParticipantTracksProps) {
  const publications = usePublications(participant);

//  let filteredPublications;

  // if (enableScreenShare && publications.some(p => p.trackName.includes('screen'))) {
  //   filteredPublications = publications.filter(p => !p.trackName.includes('camera'));
  // } else {
  //   filteredPublications = publications.filter(p => !p.trackName.includes('screen'));
  // }

  return (
    <>
      {publications.map(publication => (
        <Publication
          key={publication.kind}
          publication={publication}
          participant={participant}
          isLocalParticipant={isLocalParticipant}
          videoOnly={videoOnly}
          videoPriority={videoPriority}
        />
    ))}
    </>
  );
}


function Publication({ publication, isLocalParticipant, videoOnly, videoPriority }: PublicationProps) {
  const track = useTrack(publication);
  useDebugValue(`${publication.kind}: ${track?.name}`);

  if (!track) return null;

  switch (track.kind) {
    case 'video':
      return (
        <VideoTrack
          track={track as IVideoTrack}
          priority={videoPriority}
          isLocal={track.name.includes('camera') && isLocalParticipant}
        />
      );
    case 'audio':
      return videoOnly ? null : <AudioTrack track={track as IAudioTrack} />;
    default:
      return null;
  }
}
